import { createSlice } from "@reduxjs/toolkit";
import { EntityTemplates } from "types/entity";
import { OutletTemplates } from "types/outlet";

interface ITemplateDesc {
  productName: string;
  routes: {
    [key in EntityTemplates | OutletTemplates]?: string;
  };
  templateDetails: {
    [key in EntityTemplates | OutletTemplates]?: string;
  };
  templateData: {
    [key in EntityTemplates | OutletTemplates]?: any;
  };
}

const initialState: ITemplateDesc = {
  productName: null,
  routes: {},
  templateDetails: {},
  templateData: {},
};

const templateSlice = createSlice({
  name: "template",
  initialState,
  reducers: {
    setProductDetails: (state, { payload }) => {
      state.productName = payload;
    },
    setRoutes: (state, { payload }) => {
      state.routes[payload.template_name] = payload.route;
    },
    setTemplateDetails: (state, { payload }) => {
      state.templateDetails[payload.templateName] = payload.templateId;
    },
    setTemplateData: (state, { payload }) => {
      state.templateData[payload.template_name] = payload;
    },
  },
});

export const {
  setProductDetails,
  setRoutes,
  setTemplateDetails,
  setTemplateData,
} = templateSlice.actions;

export default templateSlice.reducer;
