import { createSlice } from "@reduxjs/toolkit";

interface IEntity {
  id?: string;
  type: string;
  favIcon?: string;
  logo?: string;
  name?: string;
  outlets?: IOutlet[];
  templateId?: string;
  selectedOutletId: string;
  selectedOutletTxnFbId: string;
}

interface IOutlet {
  id: string;
  logo: string;
  name: string;
  txnFbId: string;
  category: string;
}

const initialState: IEntity = {
  outlets: [],
  selectedOutletId: null,
  selectedOutletTxnFbId: null,
  type: null,
};

const entitySlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setEntityConfig: (state, { payload }) => {
      state.favIcon = payload.favIcon;
      state.id = payload.id;
      state.logo = payload.logo;
      state.name = payload.name;
      state.templateId = payload.feedbackTemplate;
      state.type = payload.entityType;

      state.outlets = payload?.childEntity?.map((outlet) => ({
        id: outlet.id,
        logo: outlet.logo,
        name: outlet.name,
        txnFbId: outlet.feedbackTemplate,
        category: outlet.category,
      }));
    },
    setSelectedOutletId: (state, { payload }) => {
      state.selectedOutletId = payload.outletId;
      state.selectedOutletTxnFbId = payload.outletTxnFbId;
    },
    resetEntity: () => initialState,
  },
});

export const { setEntityConfig, setSelectedOutletId, resetEntity } =
  entitySlice.actions;

export default entitySlice.reducer;
