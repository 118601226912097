import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";

interface IForm {
  bookingDetail?: any;
  firstImpression?: any;
  personalDetail?: any;
  feedbackDetail?: any;
}

const initialState: IForm = {};

const formSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateForm: (state, { payload }) => ({ ...state, ...cloneDeep(payload) }),
    resetForm: () => initialState,
  },
});

export const { updateForm, resetForm } = formSlice.actions;

export default formSlice.reducer;
