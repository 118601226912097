import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EntityPages, EntityTemplates } from "types/entity";
import { RouteTypes } from "types/layout";
import { OutletPages, OutletTemplates } from "types/outlet";

interface IEntityLayoutConfig {
  template: EntityTemplates;
  templateId: string;
  pages: {
    [key in EntityPages]: {
      name: string;
      label: string;
      route: string;
      form?: { components: [] };
      buttons?: { label: string; events: { click: { fn_name: string } } }[];
    };
  };
  stepperPages?: { type: EntityPages }[];
  layoutPages?: { main?: { type?: EntityPages }[] };
  defaultPage?: { type?: EntityPages };
  meta: any;
}

interface IOutletLayoutConfig {
  template: OutletTemplates;
  templateId: string;
  pages: {
    [key in OutletPages]?: {
      name: string;
      label: string;
      route: string;
      pageType: "stepper" | "point";
      layoutType: "main" | "end";
      shouldCallApi: boolean;
      styles: any;
      nextRoute: {
        type: RouteTypes;
        route: OutletPages;
      };
      prevRoute: {
        type: RouteTypes;
        route: OutletPages;
      };
      form?: {
        components: any[];
        buttons?: { label: string; events: { click: { fn_name: string } } }[];
      };
      buttons?: { label: string; events: { click: { fn_name: string } } }[];
      meta?: {
        bannerPath?: string;
        description?: { isVisible?: boolean; label?: string };
      };
    };
  };
  defaultPage?: { type?: OutletPages };
  meta: any;
}

interface ICompletedSteps {
  [k: number]: boolean;
}

interface IEntityLayout {
  entity: {
    config: IEntityLayoutConfig | null;
  };
  outlet: {
    config: IOutletLayoutConfig;
  };
  currentPage: EntityPages | OutletPages;
  routeType: RouteTypes;
  loadingPercentage: number;
  loadingSteps?: { type: string; value: boolean }[];
  isPageRouteFound: boolean;
  isEntityStepsSet: boolean;
  isOutletStepsSet: boolean;
  loadingLabel?: string;
  completedSteps: ICompletedSteps;
  submittedFeedbackId: string;
}

// const loadingSteps = [
//   { type: "entityLoaded"; value: false },
//   { type: "outletLoaded"; value: false }
// ];

const initialState: IEntityLayout = {
  entity: {
    config: null,
  },
  outlet: {
    config: null,
  },
  currentPage: null,
  routeType: "entity",
  loadingPercentage: 0,
  loadingSteps: [],
  isPageRouteFound: false,
  isEntityStepsSet: false,
  isOutletStepsSet: false,
  loadingLabel: null,
  completedSteps: {},
  submittedFeedbackId: null,
};

const layoutSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setCurrentPage: (
      state,
      { payload }: PayloadAction<EntityPages | OutletPages>,
    ) => {
      state.currentPage = payload;
    },
    setLayoutEntityConfig: (
      state,
      { payload }: PayloadAction<IEntityLayoutConfig>,
    ) => {
      state.entity.config = payload;
    },
    setOutletConfig: (
      state,
      { payload }: PayloadAction<IOutletLayoutConfig>,
    ) => {
      state.outlet.config = payload;
    },
    updateLoadingStatus: (state, { payload }) => {
      if (state.routeType === "entity") {
        state.loadingSteps =
          state.loadingSteps.length > 0
            ? state.loadingSteps
            : [
                { type: "entityLoaded", value: false },
                { type: "pageLoaded", value: false },
              ];
        state.isEntityStepsSet = true;
      } else if (state.routeType === "outlet") {
        state.loadingSteps =
          state.isEntityStepsSet && state.isOutletStepsSet
            ? state.loadingSteps
            : [...state.loadingSteps, { type: "outletLoaded", value: false }];

        state.isOutletStepsSet = true;
      }

      const loadingSteps = state.loadingSteps;

      const step = loadingSteps.find((step) => step.type === payload);

      if (step) {
        step.value = true;
      }

      const stepsLoaded = loadingSteps.filter((step) => step.value).length;

      state.loadingPercentage = Math.floor(
        (stepsLoaded / loadingSteps.length) * 100,
      );
    },
    updateRouteType: (state, { payload }: PayloadAction<RouteTypes>) => {
      state.routeType = payload;
    },
    updatePageRouteStatus: (state, { payload }) => {
      state.isPageRouteFound = payload;
    },
    setLoadingLabel: (state, { payload }) => {
      state.loadingLabel = payload;
    },
    updateCompletedSteps: (state, { payload }) => {
      state.completedSteps = { ...state.completedSteps, ...payload };
    },
    setSubmittedFeedbackId: (state, { payload }) => {
      state.submittedFeedbackId = payload;
    },
    resetSubmittedFeedbackId: (state) => {
      state.submittedFeedbackId = null;
    },
    resetCompletedSteps: (state) => {
      state.completedSteps = {};
    },
  },
});

export const {
  setCurrentPage,
  setLayoutEntityConfig,
  setOutletConfig,
  updateLoadingStatus,
  updateRouteType,
  updatePageRouteStatus,
  setLoadingLabel,
  updateCompletedSteps,
  resetCompletedSteps,
  resetSubmittedFeedbackId,
  setSubmittedFeedbackId,
} = layoutSlice.actions;

export default layoutSlice.reducer;

// config = {
//   template: "Artemis",
//   templateId: "1234",
//   pages: {
//     outlets: {
//       name: "outlets",
//       label: "Select Outlet",
//       route: "/outlets",
//       form: { components: [] },
//     },
//   },
//   layoutPages: {
//     main: [{ type: "outlets" }],
//   },
//   defaultPage: { type: "outlets" },
// },

// config: {
//   template: "Tokyo",
//   templateId: "1234",
//   pages: {
//     bookingDetail: {
//       name: "bookingDetail",
//       label: "Booking Detail",
//       route: "/booking-detail",
//       form: { components: [] },
//     },
//     personalDetail: {
//       name: "personalDetail",
//       label: "Personal Detail",
//       route: "/personal-detail",
//       form: { components: [] },
//     },
//   },
//   stepperPages: [{ type: "bookingDetail" }, { type: "personalDetail" }],
//   layoutPages: {
//     main: [{ type: "bookingDetail" }, { type: "personalDetail" }],
//   },
//   defaultPage: { type: "bookingDetail" },
// },
