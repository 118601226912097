import { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

const EntityRenderer = lazy(() => import("templateEngine/Renderer/Entity"));

const Main = lazy(() => import("Main"));

const AppRouter = () => {
  return (
    <>
      <Suspense fallback={null}>
        <Switch>
          <Route
            path="/:entity/:entityId/productName/:productName"
            render={() => <EntityRenderer />}
          ></Route>
          <Route path="/" exact component={Main}></Route>

          <Redirect to="/" from="/:entity" />
        </Switch>
      </Suspense>
    </>
  );
};

export default AppRouter;
